import { Box } from '@mui/material';
import VehiclesTable from '@/features/vehicles/VehiclesTable';

export default function Vehicles() {
  return (
    <Box px={0.5}>
      <VehiclesTable />
    </Box>
  );
}
