import React from 'react';
import type { PropsWithChildren } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import theme from '@/styles/theme';
import { SnackBarProvider } from 'pragmatic-ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '@/lib/react-query';
import { CircularProgress } from '@mui/material';
import { UserProvider } from '@/context/user';
import ErrorFallback from './pages/ErrorFallback';
import { Notifications } from './components/Notifications';

export default function AppProvider({ children }: PropsWithChildren) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<CircularProgress />}>
          <ErrorBoundary fallback={<ErrorFallback />}>
            <HelmetProvider>
              <SnackBarProvider>
                <QueryClientProvider client={queryClient}>
                  <UserProvider>
                    <Notifications>
                      {import.meta.env.DEV && <ReactQueryDevtools />}
                      {children}
                    </Notifications>
                  </UserProvider>
                </QueryClientProvider>
              </SnackBarProvider>
            </HelmetProvider>
          </ErrorBoundary>
        </React.Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
