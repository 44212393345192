import type { TeamInvitation } from '@/types/types';
import type { User, Team, TeamAggregate, UserMembership } from '@/types/api';

const created_at = '1725931471316.0';
const updated_at = '1726007830025.0';

export const isLoginSkip = false;

export const team: Team = {
  id: 21,
  name: 'team name',
  personal_team: false,
  created_at: created_at,
  updated_at: updated_at,
};

export const teams: Team[] = [
  team,
  {
    id: 22,
    name: 'team name',
    personal_team: false,
    created_at: created_at,
    updated_at: updated_at,
  },
  {
    id: 23,
    name: 'team name',
    personal_team: false,
    created_at: created_at,
    updated_at: updated_at,
  },
];

export const user: User = {
  _id: '1',
  name: 'user name 1',
  email: 'user email 1',
  current_team_id: 21,
  profile_photo_path: '/',
  profile_photo_url: '/',
  two_factor_enabled: false,
  email_verified_at: created_at,
  created_at: created_at,
  updated_at: updated_at,
  all_teams: [],
  current_team: team,
};

export const users: User[] = [
  user, // user
  {
    // owner

    _id: '2',
    name: 'user name 2',
    email: 'use email 2',
    current_team_id: 22,
    profile_photo_path: '/',
    profile_photo_url: '/',
    two_factor_enabled: false,
    email_verified_at: created_at,
    created_at: created_at,
    updated_at: updated_at,
  },
  {
    // membership
    _id: '3',
    name: 'user name 3',
    email: 'use email 3',
    current_team_id: 23,
    profile_photo_path: '/',
    profile_photo_url: '/',
    two_factor_enabled: false,
    email_verified_at: created_at,
    created_at: created_at,
    updated_at: updated_at,
  },
];

export const team_invitation: TeamInvitation = {
  _id: '101',
  team_id: 21,
  email: 'tika1@orchid.co.nz',
  role: 'admin',
  created_at: created_at,
  updated_at: updated_at,
};
export const team_invitations: TeamInvitation[] = [
  team_invitation,
  {
    _id: '102',
    team_id: 22,
    email: 'tika2@orchid.co.nz',
    role: 'admin',
    created_at: created_at,
    updated_at: updated_at,
  },
  {
    _id: '103',
    team_id: 23,
    email: 'tika3@orchid.co.nz',
    role: 'admin',
    created_at: created_at,
    updated_at: updated_at,
  },
];

export const userMembership: UserMembership = {
  ...users[2],
  membership: {
    role: 'admin',
  },
};
export const membershipUsers: UserMembership[] = [userMembership];

export const teamAggregate: TeamAggregate = {
  ...(user?.current_team as Team),
  owner: users[1],
  team_invitations: team_invitations,
  users: membershipUsers,
};
export const branches = [
  [
    { id: 111, name: 'branch 1' },
    { id: 112, name: 'branch 2' },
    { id: 113, name: 'branch 3' },
  ],
  [
    { id: 114, name: 'branch 4' },
    { id: 115, name: 'branch 5' },
    { id: 116, name: 'branch 6' },
  ],
  [
    { id: 117, name: 'branch 7' },
    { id: 118, name: 'branch 8' },
    { id: 119, name: 'branch 9' },
  ],
];
export const regions = [
  [
    { id: 121, name: 'region 1' },
    { id: 122, name: 'region 2' },
    { id: 123, name: 'region 3' },
  ],
  [
    { id: 124, name: 'region 4' },
    { id: 125, name: 'region 5' },
    { id: 126, name: 'region 6' },
  ],
  [
    { id: 127, name: 'region 7' },
    { id: 128, name: 'region 8' },
    { id: 129, name: 'region 9' },
  ],
];
export const mockBranchOptions = branches.flat();
export const mockRegionOptions = regions.flat();
// export const pageProps = {
//   auth: user,
//   clients: clients,
//   vehicles: vehicles,
//   errorBags: [],
//   errors: {},
//   // jetstream:
//   teamInvitation: null,
// };
