import { Box } from '@mui/material';
import TeamTable from '@/features/teams/TeamTable';
import Head from '@/components/Head';

export default function TeamShow() {
  return (
    <>
      <Head title="Team" />
      <Box component="div" px={1}>
        <TeamTable />
      </Box>
    </>
  );
}
