import React, { useCallback, useContext } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  QueryFunction,
  MutationFunction,
} from '@tanstack/react-query';
import api from '@/lib/api-client';
import { AuthResponse, User, CustomQueryOptions } from '@/types/api';
import { LoginInput, RegisterInput, ProfileInput } from '@/types/formInput';
import { rqKeys } from '@/lib/react-query';
import { CircularProgress, Stack } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import { SnackBarContext } from 'pragmatic-ui';

interface Config<User, LoginCredentials, RegisterCredentials> {
  getUser: QueryFunction<User, QueryKey>;
  login: MutationFunction<User, LoginCredentials>;
  register: MutationFunction<User, RegisterCredentials>;
  logout: MutationFunction<unknown, unknown>;
  authKey?: QueryKey;
}

export function configureAuth<
  Auth,
  Error,
  LoginCredentials,
  RegisterCredentials,
>(config: Config<Auth, LoginCredentials, RegisterCredentials>) {
  const { getUser, authKey = [rqKeys.auth], login, register, logout } = config;

  const useAuthUser = (options?: CustomQueryOptions<Auth, Error>) =>
    useQuery({
      queryKey: authKey,
      queryFn: getUser,
      throwOnError: false,
      ...options,
    });

  const useLogin = ({ callback }: { callback: () => void }) => {
    const queryClient = useQueryClient();

    const setUser = useCallback(
      (data: Auth) => queryClient.setQueryData(authKey, data),
      [queryClient],
    );

    return useMutation({
      mutationFn: login,
      onSuccess: (user) => {
        setUser(user);
        callback();
      },
    });
  };

  const useRegister = ({ callback }: { callback: () => void }) => {
    const { showSnack } = useContext(SnackBarContext);
    const queryClient = useQueryClient();

    const setUser = useCallback(
      (data: Auth) => queryClient.setQueryData(authKey, data),
      [queryClient],
    );

    return useMutation({
      mutationFn: register,
      onSuccess: (user) => {
        setUser(user);
        callback();
      },
      onError: (err) => {
        showSnack(JSON.stringify(err), 'error');
      },
    });
  };

  const useLogout = ({ callback }: { callback: () => void }) => {
    const { showSnack } = useContext(SnackBarContext);
    const queryClient = useQueryClient();

    const setUser = useCallback(
      (user: Auth | null) => queryClient.setQueryData(authKey, user),
      [queryClient],
    );

    return useMutation({
      mutationFn: logout,
      onSuccess: () => {
        setUser(null);
        callback();
      },
      onError: (err) => {
        showSnack(JSON.stringify(err), 'error');
      },
    });
  };

  const useProfileUpdate = () => {
    const { showSnack } = useContext(SnackBarContext);
    const queryClient = useQueryClient();

    const setUser = useCallback(
      (user: User) => {
        queryClient.setQueryData(authKey, user);
      },
      [queryClient],
    );

    return useMutation({
      mutationFn: updateProfile,
      onSuccess: (user) => {
        setUser(user);
        showSnack('Successfully updated the profile', 'success');
      },
      onError: (err) => {
        showSnack(JSON.stringify(err), 'error');
      },
    });
  };

  function AuthLoader({
    children,
    renderLoading,
    renderUnauthenticated,
    renderError = (error: Error) => <>{JSON.stringify(error)}</>,
  }: {
    children: React.ReactNode;
    renderLoading: () => JSX.Element;
    renderUnauthenticated?: () => JSX.Element;
    renderError?: (error: Error) => JSX.Element;
  }) {
    const { isSuccess, isFetched, status, data, error } = useAuthUser();

    if (isSuccess) {
      if (renderUnauthenticated && !data) {
        return renderUnauthenticated();
      }
      return <>{children}</>;
    }

    if (!isFetched) {
      return renderLoading();
    }

    if (status === 'error' && import.meta.env.DEV) {
      return renderError(error);
    }

    return null;
  }

  return {
    useAuthUser,
    useLogin,
    useRegister,
    useLogout,
    useProfileUpdate,
    AuthLoader,
  };
}

const getUser = (): Promise<User> => {
  return api.get('/v1/admin/auth/me');
};

const loginWithEmailAndPassword = (data: LoginInput): Promise<AuthResponse> => {
  return api.post('/v1/admin/auth/login', data);
};

const registerWithEmailAndPassword = (
  data: RegisterInput,
): Promise<AuthResponse> => {
  return api.post('/auth/register', data);
};

function logout(): Promise<void> {
  return api.post('/v1/admin/auth/logout');
}

async function login(data: LoginInput) {
  const response = await loginWithEmailAndPassword(data);
  return response.user;
}

async function register(data: RegisterInput) {
  const response = await registerWithEmailAndPassword(data);
  return response.user;
}

async function updateProfile(payload: ProfileInput): Promise<User> {
  return api.post('/auth/profile', payload);
}

const authConfig = {
  getUser: getUser,
  login: login,
  register: register,
  logout: logout,
  updateProfile: updateProfile,
};

export const {
  useAuthUser,
  useLogin,
  useLogout,
  useRegister,
  useProfileUpdate,
  AuthLoader,
} = configureAuth(authConfig);

// todo: this file needs factoring. don't want this inside a useAuth file under the hooks directory...
export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading } = useAuthUser();
  const location = useLocation();

  if (isLoading) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '100vh' }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (!data) {
    return (
      <Navigate
        to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  return children;
};
