export default function ApplicationLogo() {
  return (
    <svg
      width="40"
      height="60"
      viewBox="0 0 40 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.2897 19.6537C36.0335 15.0862 31.4746 12.2849 26.6117 12.2083C25.5795 12.1918 18.2825 12.2137 17.1215 12.2137C14.0521 12.2137 13.1595 13.7499 13.1595 16.4882C13.1485 22.581 13.1485 38.841 13.1595 45.5992C13.154 45.5992 13.1513 45.5992 13.1458 45.5992V59.2551C16.2453 59.2551 18.8602 57.1904 19.6926 54.3618C19.7692 54.1317 19.8322 53.8661 19.876 53.5594C19.9363 53.1925 19.9719 52.8146 19.9719 52.4285C19.9719 52.3436 19.9691 52.2615 19.9664 52.1766C19.9664 45.7881 19.9499 28.8243 19.9719 22.4495C19.9719 20.1986 20.4538 18.9993 23.6738 18.9993C24.0653 18.9993 26.5269 18.9664 26.9294 19.013C30.393 19.4045 32.9669 22.2989 32.8327 25.6506C32.693 29.1556 29.8701 31.7297 26.2969 32.165C23.2274 32.5375 22.4635 35.8015 22.6114 38.7178C23.4848 38.7178 25.6315 38.6904 26.4228 38.6767C36.2553 38.5042 42.5858 28.3533 38.287 19.6565L38.2897 19.6537Z"
        fill="url(#paint0_linear_9050_1234)"
      />
      <mask
        id="mask0_9050_1234"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="13"
        y="12"
        width="27"
        height="46"
      >
        <path
          d="M24.8347 38.7151C25.7082 38.7151 25.6342 38.6877 26.4255 38.674C36.258 38.5015 42.5885 28.3506 38.2897 19.6537C36.0335 15.0862 31.4746 12.2849 26.6117 12.2083C25.5795 12.1918 18.2825 12.2137 17.1215 12.2137C14.0521 12.2137 13.1595 13.7499 13.1595 16.4882C13.1485 22.8685 13.1513 38.4823 13.1595 44.8626C13.165 47.9897 14.137 59.1155 15.443 57.3821C17.5541 55.2599 19.9664 50.9553 19.9664 46.2126C19.9664 39.8323 19.95 28.827 19.9719 22.4468C19.9719 20.1959 20.4538 18.9965 23.6738 18.9965C24.0653 18.9965 26.5269 18.9637 26.9294 19.0102C30.393 19.4018 32.9669 22.2962 32.8327 25.6479C32.693 29.1529 29.8865 31.8228 26.2859 31.8666C25.5329 31.8748 25.6452 31.9049 24.8292 31.9049V38.7123L24.8347 38.7151Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_9050_1234)">
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.8">
          <rect
            x="10.2626"
            y="0.236328"
            width="34.2262"
            height="34.2288"
            fill="url(#pattern0_9050_1234)"
          />
        </g>
      </g>
      <path
        d="M32.4302 12.8733C30.6094 4.76513 23.1398 0.0853634 15.0406 0.0853634C11.4673 0.0853634 7.46972 0.0771484 4.63579 0.0771484C0.964001 0.0798867 0.345191 1.99396 0.345191 4.49951C0.345191 12.238 0.331501 38.293 0.350667 46.0314C0.350667 46.0588 0.353406 46.0835 0.353406 46.1108V59.1972C4.75079 59.1972 8.34317 55.7415 8.55948 51.3985C8.58138 51.1521 8.59507 50.8947 8.59507 50.6236C8.61424 42.6633 8.58138 21.6714 8.60603 13.7112C8.61972 9.05332 9.10436 8.34958 13.3073 8.34958C13.8851 8.34958 17.0777 8.33862 17.6773 8.44268C21.7954 9.14916 24.7471 12.7418 24.4788 16.7151C24.3583 18.5197 23.6519 20.1051 22.595 21.3784C22.0334 22.0538 20.8931 23.1743 19.9692 23.3445C19.972 25.1464 19.9555 28.2899 19.9583 32.0222C20.1609 32.0113 20.3251 32.0086 20.5359 31.9565C28.7831 29.7659 34.366 21.4962 32.4329 12.8815L32.4302 12.8733Z"
        fill="url(#paint1_linear_9050_1234)"
      />
      <mask
        id="mask1_9050_1234"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="0"
        width="30"
        height="33"
      >
        <path
          d="M32.4302 12.8729C30.6094 4.76473 23.1398 0.0849609 15.0405 0.0849609C2.82154 0.0849609 -2.50487 8.34917 13.3073 8.34917C13.8851 8.34917 17.0777 8.33822 17.6773 8.44228C21.7954 9.14876 24.7471 12.7414 24.4788 16.7147C24.3583 18.5192 23.6519 20.1047 22.5949 21.378C22.0334 22.0534 20.8899 23.1739 19.966 23.3441C19.9687 25.146 19.9523 28.2895 19.955 32.0218C20.1576 32.0109 20.3251 32.0082 20.5359 31.9561C28.783 29.7655 34.366 21.4958 32.4329 12.8811L32.4302 12.8729Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_9050_1234)">
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.8">
          <rect
            x="-1.51125"
            y="10.3672"
            width="34.2262"
            height="33.955"
            fill="url(#pattern1_9050_1234)"
          />
        </g>
      </g>
      <defs>
        <pattern
          id="pattern0_9050_1234"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use transform="scale(0.008)" />
        </pattern>
        <pattern
          id="pattern1_9050_1234"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use transform="scale(0.008 0.00806452)" />
        </pattern>
        <linearGradient
          id="paint0_linear_9050_1234"
          x1="28.3477"
          y1="18.3448"
          x2="13.0482"
          y2="45.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F26419" />
          <stop offset="0.68" stopColor="#CE4721" />
          <stop offset="1" stopColor="#BC3926" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9050_1234"
          x1="18.11"
          y1="9.21488"
          x2="-0.448188"
          y2="38.9735"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F26419" />
          <stop offset="0.68" stopColor="#CE4721" />
          <stop offset="1" stopColor="#BC3926" />
        </linearGradient>
        <image id="image0_9050_1234" width="125" height="125" />
        <image id="image1_9050_1234" width="125" height="124" />
      </defs>
    </svg>
  );
}
