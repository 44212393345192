import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Typography,
  Fab,
  darken,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import type { Nullable } from '@/types/types';
import type { User } from '@/types/api';
import TeamMemberManager from '@/features/teams/TeamMemberManager';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PencilOutlinedIcon from '@/components/icons/PencilOutlinedIcon';
import CustomDataGrid from '@/components/CustomDataGrid';
import { useUsers } from '@/hooks/useUsers';

const TeamTable = () => {
  const { data: users, isLoading } = useUsers();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [teamMemberBeingRemoved, setTeamMemberBeingRemoved] =
    useState<Nullable<User>>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  if (!users) return null;

  const columns: GridColDef[] = [
    {
      field: 'gravatar',
      headerName: '',
      minWidth: 60,
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={"100%"}
          >
            <Avatar src={params.row.gravatar} />
          </Box>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 350,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 350,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Role',
      minWidth: 100,
      flex: 1,
      valueGetter: (val) =>
        String(val).charAt(0).toUpperCase() + String(val).slice(1),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: () => {
        return (
          <Fab
            color="primary"
            size="small"
            aria-label="edit"
            sx={{
              mr: 2,
              '&:hover': {
                backgroundColor: darken('#F6AE2D', 0.2),
              },
            }}
            // onClick={() => handleEditButtonClicked(row)}
          >
            <PencilOutlinedIcon />
          </Fab>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Box>
        <CustomDataGrid
          rows={users}
          getRowId={(user) => user._id}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={users.length}
          loading={isLoading}
        />
      </Box>

      {/* Remove team member dialog */}
      <Dialog
        open={!!teamMemberBeingRemoved}
        onClose={() => setTeamMemberBeingRemoved(null)}
        maxWidth="xs"
      >
        <DialogTitle>Remove Team Member</DialogTitle>
        <DialogContent>
          Are you sure you would like to remove this person from the team?
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setTeamMemberBeingRemoved(null)}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            // onClick={removeTeamMember}
            disabled={isLoading}
            variant="contained"
            size="small"
            color="primary"
            sx={{ ml: 1 }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* User edit drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '30% !important',
            background: '#333C45',
            borderRadius: '12px 0px 0px 12px !important',
          },
        }}
      >
        <div role="presentation">
          <Box component="div" m={3}>
            <Typography variant="h6">
              <ArrowBackIcon
                sx={{ cursor: 'pointer', mr: 3 }}
                onClick={() => setDrawerOpen(false)}
              />
              Edit User
            </Typography>
          </Box>
          <Box m={4}>
            <TeamMemberManager
              setDrawerOpen={setDrawerOpen}
              selectedUser={selectedUser}
            />
          </Box>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default TeamTable;
