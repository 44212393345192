function DashboardIcon() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1666 10.2083H16.0416C15.2366 10.2083 14.5833 9.555 14.5833 8.75V5.83333C14.5833 5.02833 15.2366 4.375 16.0416 4.375H29.1666C29.9716 4.375 30.6249 5.02833 30.6249 5.83333V8.75C30.6249 9.555 29.9716 10.2083 29.1666 10.2083Z"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1666 20.4168H16.0416C15.2366 20.4168 14.5833 19.7635 14.5833 18.9585V16.0418C14.5833 15.2368 15.2366 14.5835 16.0416 14.5835H29.1666C29.9716 14.5835 30.6249 15.2368 30.6249 16.0418V18.9585C30.6249 19.7635 29.9716 20.4168 29.1666 20.4168Z"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1666 30.6248H16.0416C15.2366 30.6248 14.5833 29.9715 14.5833 29.1665V26.2498C14.5833 25.4448 15.2366 24.7915 16.0416 24.7915H29.1666C29.9716 24.7915 30.6249 25.4448 30.6249 26.2498V29.1665C30.6249 29.9715 29.9716 30.6248 29.1666 30.6248Z"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 10.2083H5.83333C5.02833 10.2083 4.375 9.555 4.375 8.75V5.83333C4.375 5.02833 5.02833 4.375 5.83333 4.375H8.75C9.555 4.375 10.2083 5.02833 10.2083 5.83333V8.75C10.2083 9.555 9.555 10.2083 8.75 10.2083Z"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 20.4168H5.83333C5.02833 20.4168 4.375 19.7635 4.375 18.9585V16.0418C4.375 15.2368 5.02833 14.5835 5.83333 14.5835H8.75C9.555 14.5835 10.2083 15.2368 10.2083 16.0418V18.9585C10.2083 19.7635 9.555 20.4168 8.75 20.4168Z"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 30.6248H5.83333C5.02833 30.6248 4.375 29.9715 4.375 29.1665V26.2498C4.375 25.4448 5.02833 24.7915 5.83333 24.7915H8.75C9.555 24.7915 10.2083 25.4448 10.2083 26.2498V29.1665C10.2083 29.9715 9.555 30.6248 8.75 30.6248Z"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DashboardIcon;
