import { useContext, useEffect } from 'react';
import { SnackBarContext } from 'pragmatic-ui';
import { useNotifications } from '@/stores/notifications-store';

type NotificationsProps = {
  children: React.ReactNode;
};

export function Notifications({ children }: NotificationsProps) {
  const { notifications } = useNotifications();
  const { showSnack } = useContext(SnackBarContext);

  useEffect(() => {
    if (notifications.length > 0) {
      if (notifications[notifications.length - 1]?.message) {
        showSnack(
          notifications[notifications.length - 1].message as string,
          notifications[notifications.length - 1]?.type,
          {
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          },
        );
      }
    }
  }, [notifications, showSnack]);

  return <>{children}</>;
}
