import CustomDataGrid from '@/components/CustomDataGrid';
import UpdateCompanyDrawer from '@/features/dashboard/UpdateCompanyDrawer';
import { Box, Chip, ChipProps, CircularProgress } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useCompanies } from '@/hooks/useCompanies';
import { useVehicles } from '@/hooks/useVehicles';
import { useNavigate } from 'react-router-dom';
import { useConfigurations } from '@/hooks/useConfigurations';

const tenDaysFromNow = (): number => {
  const now = new Date();
  const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
  const tenDaysLater = new Date(now.getTime() + tenDaysInMilliseconds);
  return tenDaysLater.getTime();
};

interface TableChipProps extends ChipProps {
  labelText: string | number;
  labelIconColor?: string;
}

function TableChip({
  labelText,
  labelIconColor = 'primary',
  ...props
}: TableChipProps) {
  return (
    <Chip
      {...props}
      size="small"
      variant="filled"
      label={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ fontWeight: 800 }}
        >
          {labelText}
          <ChevronRightIcon
            sx={{ ml: 1, fontSize: '18px', color: labelIconColor }}
          />
        </Box>
      }
    />
  );
}

export default function ClientTable() {
  const { data: companies = [], isLoading: isLoadingCompanies } =
    useCompanies();
  const { data: vehicles = [], isLoading: isLoadingVehicles } = useVehicles();
  const { data: configurations = [], isLoading: isLoadingConfigurations } =
    useConfigurations();
  const navigate = useNavigate();

  const isLoading =
    isLoadingCompanies || isLoadingVehicles || isLoadingConfigurations;
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 300, flex: 1 },
    {
      field: 'branches',
      headerName: 'Branches',
      minWidth: 275,
      flex: 1,
      valueGetter: (params: any) => {
        return params.reduce(
          (acc: string, current: string, index: number, array: string[]) => {
            return acc + current + (index < array.length - 1 ? ', ' : '');
          },
          '',
        );
      },
    },
    {
      field: 'regions',
      headerName: 'Regions',
      minWidth: 275,
      flex: 1,
      valueGetter: (params: any) => {
        return params.reduce(
          (acc: string, current: string, index: number, array: string[]) => {
            return acc + current + (index < array.length - 1 ? ', ' : '');
          },
          '',
        );
      },
    },
    {
      field: 'vehiclesCount',
      headerName: '# Vehicles',
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => {
        const vehiclesCount =
          vehicles.filter((vehicle) => params.row._id === vehicle.company._id)
            ?.length || 0;

        const filteredVehiclesUrl = `/vehicles?companyId=${params.row._id}`;
        return (
          <TableChip
            color="secondary"
            labelText={vehiclesCount}
            labelIconColor="primary.main"
            onClick={() => navigate(filteredVehiclesUrl)}
            sx={{
              '&:hover': {
                backgroundColor: '#F26419', // Hover color
              },
            }}
          />
        );
      },
    },
    {
      field: 'upcomingExpiredPermits',
      headerName: '# Upcoming Expired Permits',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        const tenDaysLater = tenDaysFromNow();
        const expiringPermitsCount =
          configurations
            .filter(({ company }) => company === params.row._id)
            .flatMap(({ permits }) => permits)
            .filter(
              ({ expiryDate }) =>
                new Date(expiryDate).getTime() <= tenDaysLater &&
                new Date(expiryDate) > new Date(),
            ).length ?? 0;

        return (
          <TableChip
            color={'primary'}
            labelText={expiringPermitsCount}
            onClick={() => navigate('/permits?permitStatus=expiring')}
            disabled={expiringPermitsCount === 0 ? true : false}
          />
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <UpdateCompanyDrawer
              company={companies.find(
                (company) => company._id === params.row._id,
              )}
            />
          </>
        );
      },
    },
  ];

  return (
    <CustomDataGrid
      rows={companies}
      columns={columns}
      pagination
      getRowId={(row) => row._id}
      // hideFooterRowCount={true}
      // hideFooter={true}
      // disableRowSelectionOnClick={true}
      autoHeight={false}
    />
  );
}
