import { Box } from '@mui/material';
import EditVehicleForm from '@/features/vehicles/EditVehicleForm';

export default function VehiclesEdit() {
  return (
    <Box px={0.5}>
      <EditVehicleForm />
    </Box>
  );
}
