export default function PermitsIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6667 10.2629V9.97119C29.6667 7.52096 29.6667 6.29585 29.1898 5.35998C28.7704 4.53677 28.1011 3.86748 27.2779 3.44804C26.342 2.97119 25.1169 2.97119 22.6667 2.97119H13.3333C10.8831 2.97119 9.65798 2.97119 8.72212 3.44804C7.89891 3.86748 7.22962 4.53677 6.81017 5.35998C6.33333 6.29585 6.33333 7.52096 6.33333 9.97119V25.1379C6.33333 27.5881 6.33333 28.8132 6.81017 29.7491C7.22962 30.5723 7.89891 31.2416 8.72212 31.661C9.65798 32.1379 10.8831 32.1379 13.3333 32.1379H18.7292M18.7292 16.0962H12.1667M17.2708 21.9295H12.1667M23.8333 10.2629H12.1667M26.75 26.3045V18.2837C26.75 17.0756 27.7294 16.0962 28.9375 16.0962C30.1456 16.0962 31.125 17.0756 31.125 18.2837V26.3045C31.125 28.7208 29.1662 30.6795 26.75 30.6795C24.3337 30.6795 22.375 28.7208 22.375 26.3045V20.4712"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
