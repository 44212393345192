/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  type DefaultOptions,
  QueryClient,
  type UseMutationOptions,
} from '@tanstack/react-query';

export const queryConfig = {
  queries: {
    throwOnError: !import.meta.env.DEV,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60,
    keepPreviousData: true,
  },
} as DefaultOptions;

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

export type ApiFnReturnType<FnType extends (...args: any) => Promise<any>> =
  Awaited<ReturnType<FnType>>;

export type QueryConfig<T extends (...args: any[]) => any> = Omit<
  ReturnType<T>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<
  MutationFnType extends (...args: any) => Promise<any>,
> = UseMutationOptions<
  ApiFnReturnType<MutationFnType>,
  Error,
  Parameters<MutationFnType>[0]
>;

export const rqKeys = {
  auth: 'authenticated-user',
  clients: 'clients',
  team: 'team',
  teams: 'teams',
  teamsAggregate: 'teamsAggregate',
  vehicles: 'vehicles',
};
