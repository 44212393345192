import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
  description: string;
}

export default function SectionTitle({ title, description }: Props) {
  return (
    <Box pr={4}>
      <Typography variant="body1" fontWeight="bold" fontSize="larger">
        {title}
      </Typography>

      <Typography
        variant="body1"
        mt={2}
        color="textSecondary"
        fontSize="medium"
      >
        {description}
      </Typography>
    </Box>
  );
}
