export default function TractorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="33"
      viewBox="0 0 39 33"
      fill="none"
    >
      <path
        d="M19.0156 28.7476L27.9808 28.7476"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M37.0156 23.7214V5.47299C32.4872 5.47299 23.2194 5.47299 22.3753 5.47299C21.3201 5.47299 19.7373 5.20917 19.7373 7.51906C19.7373 9.36697 19.7373 17.4591 19.7373 21.441C16.374 21.441 9.22532 21.441 7.53707 21.441C5.42675 21.441 1.01562 23.3064 1.01562 28.0582"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M36.7514 15.1087C35.1467 15.1087 31.6999 15.1087 30.7503 15.1087C29.5632 15.1087 27.9805 14.6467 27.9805 12.0728C27.9805 10.0137 27.9805 6.94723 27.9805 5.67139"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M37.0161 4.46764L21.6504 1.2998V4.46764"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2012 19.0928H29.959"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.87793 20.1483V18.0366H12.8456V20.1483"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="8.46501"
        cy="28.9652"
        rx="2.93278"
        ry="2.93497"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
      <ellipse
        cx="15.7785"
        cy="28.9652"
        rx="2.93278"
        ry="2.93497"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
      <ellipse
        cx="31.0802"
        cy="28.9652"
        rx="2.93278"
        ry="2.93497"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
    </svg>
  );
}
