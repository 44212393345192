import { Box } from '@mui/material'
import Head from '@/components/Head';
import CompaniesTable from '@/features/dashboard/CompaniesTable';

export default function Dashboard() {
  return (
    <>
      <Head title="Our Clients" />
      <Box px={0.5}>
        <CompaniesTable />
      </Box>
    </>
  );
}
