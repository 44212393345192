const LogoutIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4167 5.83325L26.25 5.83325C27.8608 5.83325 29.1667 7.13909 29.1667 8.74992V26.2499C29.1667 27.8607 27.8608 29.1666 26.25 29.1666H20.4167M4.375 17.4999L21.875 17.4999M4.375 17.4999L10.2083 11.6666M4.375 17.4999L10.2083 23.3333"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
