import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '@/context/user';

function PublicRoute({ children }: { children: React.ReactNode }) {
  const { user = null } = useContext(UserContext);

  if (user && user._id) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}

export default PublicRoute;
