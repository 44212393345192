import api from '@/lib/api-client';
import { User } from '@/types/api';
import { UseQueryOptions, QueryKey, useQuery } from '@tanstack/react-query';

const userKey = ['users'];

const getAdminUsers = async (): Promise<User[]> => {
  const response = await api.get('/v1/admin/users');
  return response.data;
};

export const useUsers = (
  options?: Omit<
    UseQueryOptions<User[], Error, User[], QueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryKey: userKey,
    queryFn: getAdminUsers,
    ...options,
  });
