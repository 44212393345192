import { PropsWithChildren } from 'react';
import { Fade, Box, SxProps } from '@mui/material';

interface Props {
  on: boolean;
  sx?: SxProps;
}

export default function ActionMessage({
  on,
  sx,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Box sx={sx}>
      <Fade in={on} timeout={1000}>
        <Box
          component="div"
          sx={{ typography: 'body2', color: 'text.secondary' }}
        >
          {children}
        </Box>
      </Fade>
    </Box>
  );
}
