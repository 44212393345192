import DeleteUserForm from '@/features/profile/DeleteUserForm';
import TwoFactorAuthenticationForm from '@/features/profile/TwoFactorAuthenticationForm';
import UpdatePasswordForm from '@/features/profile/UpdatePasswordForm';
import UpdateProfileInformationForm from '@/features/profile/UpdateProfileInformationForm';
import { Grid } from '@mui/material';
import Head from '@/components/Head';

export default function Profile() {
  const isCanUpdateProfileInformation = true;
  const isCanUpdatePassword = true;
  const isHasAccountDeletionFeatures = true;

  return (
    <Grid container spacing={6} paddingX={6} marginTop="-20px">
      <Head title="Profile" />
      {isCanUpdateProfileInformation ? (
        <Grid item xs={12}>
          <UpdateProfileInformationForm />
        </Grid>
      ) : null}

      {isCanUpdatePassword ? (
        <Grid item xs={12}>
          <UpdatePasswordForm />
        </Grid>
      ) : null}

      {isHasAccountDeletionFeatures ? (
        <Grid item xs={12} marginBottom={2}>
          <DeleteUserForm />
        </Grid>
      ) : null}
    </Grid>
  );
}
