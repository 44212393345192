import Head from '@/components/Head';
import EditConfigurationForm from '@/features/configurations/EditConfigurationForm';
import { Box } from '@mui/material';

export default function EditConfiguration() {
  return (
    <>
      <Head title="Create configuration" />
      <Box>
        <EditConfigurationForm />
      </Box>
    </>
  );
}
