import { PropsWithChildren } from 'react';
import SectionTitle from '@/components/SectionTitle';
import { Box, Grid } from '@mui/material';

interface Props {
  title: string;
  description: string;
}

export default function ActionSection({
  title,
  description,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Grid container spacing={6}>
      <Grid item md={4}>
        <SectionTitle title={title} description={description} />
      </Grid>

      <Grid item md={8}>
        <Box
          mt={2}
          mb={2}
          pl={4}
          pr={6}
          sx={{
            mt: { sm: 0, xs: 0 },
            pl: { sm: 0, xs: 0 },
            pr: { sm: 0, xs: 0 },
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}
