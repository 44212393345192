export default function PencilOutlinedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M4.80005 16.1162L4.09294 15.4091L3.80005 15.702V16.1162H4.80005ZM17.8 3.11621L18.5072 2.4091C18.1166 2.01858 17.4835 2.01858 17.0929 2.4091L17.8 3.11621ZM21.8 7.11621L22.5072 7.82332C22.8977 7.43279 22.8977 6.79963 22.5072 6.4091L21.8 7.11621ZM8.80005 20.1162V21.1162H9.21426L9.50716 20.8233L8.80005 20.1162ZM4.80005 20.1162H3.80005C3.80005 20.6685 4.24776 21.1162 4.80005 21.1162V20.1162ZM5.50716 16.8233L18.5072 3.82332L17.0929 2.4091L4.09294 15.4091L5.50716 16.8233ZM17.0929 3.82332L21.0929 7.82332L22.5072 6.4091L18.5072 2.4091L17.0929 3.82332ZM21.0929 6.4091L8.09294 19.4091L9.50716 20.8233L22.5072 7.82332L21.0929 6.4091ZM8.80005 19.1162H4.80005V21.1162H8.80005V19.1162ZM5.80005 20.1162V16.1162H3.80005V20.1162H5.80005ZM14.0929 6.82332L18.0929 10.8233L19.5072 9.4091L15.5072 5.4091L14.0929 6.82332Z"
        fill="#202D38"
      />
    </svg>
  );
}
