import myAxios from '@/lib/api-client';
import { resetPasswordSchema } from '@/lib/validation/auth';
import { Box, Button, Container, styled } from '@mui/material';
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo';
import Formik from '@/components/Formik';
import { PTextField } from 'pragmatic-ui';
import Head from '@/components/Head';
import { user } from '@/mockData';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

export default function ResetPassword() {
  // TODO ON HOLD: token & email
  const token = 'token';
  const email = user.email;

  // TODO ON HOLD: set up correctly
  const handleSubmit = () => {
    myAxios.post('/user/password/reset').then(({ data }) => {
      console.log(data);
    });
  };
  const formikProps = {
    initialValues: {
      token,
      email,
      password: '',
      password_confirmation: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: handleSubmit,
  };

  return (
    <Container component="main" maxWidth="xs">
      <Head title="Reset Password" />

      <Box
        sx={{
          marginTop: 30,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AuthenticationCardLogo />

        <Formik {...formikProps}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {({ handleSubmit, isValid, isSubmitting }: any) => (
            <StyledForm onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <PTextField
                required
                fullWidth
                id="email"
                type="text"
                label="Email Address"
                name="email"
                autoComplete="on"
              />
              <PTextField
                required
                fullWidth
                id="password"
                type="password"
                label="Password"
                name="password"
                autoComplete="password"
              />
              <PTextField
                required
                fullWidth
                id="password"
                type="password"
                label="Confirm Password"
                name="password_confirmation"
                autoComplete="password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!isValid || isSubmitting}
                sx={{ mt: 2, mb: 2 }}
              >
                Reset Password
              </Button>
            </StyledForm>
          )}
        </Formik>
      </Box>
    </Container>
  );
}
