import { Typography, Box, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  message?: string;
  sx?: SxProps;
}

export default function InputError({
  message,
  sx: sxProps,
  children,
}: PropsWithChildren<Props>) {
  if (!message && !children) {
    return null;
  }
  return (
    <Box sx={sxProps}>
      <Typography variant="body2" sx={{ color: '#E61B00' }}>
        {message || children}
      </Typography>
    </Box>
  );
}
