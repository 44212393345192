import ErrorPage from '@/pages/ErrorPage';
import Dashboard from '@/pages/Dashboard';
import Vehicles from '@/pages/Vehicles';
import VehiclesEdit from '@/pages/VehiclesEdit';
import Teams from '@/pages/Teams';
import TeamsCreate from '@/pages/TeamsCreate';
import Profile from '@/pages/Profile';
import Configurations from '@/pages/Configurations';
import Permits from '@/pages/Permits';
import type { Permissions, AvailableRole } from '@/types/types';

export const availablePermissions = ['create', 'delete', 'read', 'update'];
export const permissions: Permissions = {
  canAddTeamMembers: true,
  canDeleteTeam: true,
  canRemoveTeamMembers: true,
  canUpdateTeam: true,
  canUpdateTeamMembers: true,
};
export const defaultPermissions = ['read'];

export const availableRoles: AvailableRole[] = [
  {
    description: 'Administrator users can perform any action.',
    key: 'admin',
    name: 'Admin',
    permissions: ['create', 'delete', 'read', 'update'],
  },
  {
    description: 'Editor users have the ability to read, create, and update.',
    key: 'editor',
    name: 'Editor',
    permissions: ['create', 'read', 'update'],
  },
  {
    description: 'View only users only have the ability to read',
    key: 'view-only',
    name: 'View Only',
    permissions: ['read'],
  },
];

export type PageRoute = {
  path: string;
  name: string;
  element: JSX.Element;
  errorElement: JSX.Element;
};

export const pageRoutes: PageRoute[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    element: <Vehicles />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/vehicles/edit',
    name: 'VehiclesEdit',
    element: <VehiclesEdit />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/teams',
    name: 'Team',
    element: <Teams />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/teams/create',
    name: 'Team Create',
    element: <TeamsCreate />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile',
    name: 'Profile',
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/configurations',
    name: 'Add New Vehicle',
    element: <Configurations />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/permits',
    name: 'Permits',
    element: <Permits />,
    errorElement: <ErrorPage />,
  },
];

// this is temporary
export const TYPE_OPTIONS = ['Area', 'Continuous', 'Multi', 'Single', 'HPMV'];