import { useCallback, useContext } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  QueryFunction,
  MutationFunction,
  QueryFunctionContext,
} from '@tanstack/react-query';
import myAxios from '@/lib/api-client';
import type { Team } from '@/types/api';
import { TeamMemberInput, TeamMemberUpdateInput } from '@/types/formInput';
import { rqKeys } from '@/lib/react-query';
import { SnackBarContext } from 'pragmatic-ui';

interface Config<Team, TeamCredentials> {
  getTeam: QueryFunction<Team, QueryKey>;
  addTeamMember: MutationFunction<Team, TeamCredentials>;
  updateTeamMember: MutationFunction<Team, TeamCredentials>;
  teamKey?: QueryKey;
}

export function configureAuth<Team, TeamCredentials>(
  config: Config<Team, TeamCredentials>,
) {
  const {
    getTeam,
    addTeamMember,
    updateTeamMember,
    teamKey = [rqKeys.team],
  } = config;

  const useTeam = (userId: string) =>
    useQuery({
      queryKey: [...teamKey, userId],
      queryFn: getTeam,
    });

  const useTeamMemberAdd = () => {
    const { showSnack } = useContext(SnackBarContext);
    const queryteam = useQueryClient();

    const setTeam = useCallback(
      (Team: Team) => {
        queryteam.setQueryData(teamKey, Team);
      },
      [queryteam],
    );

    return useMutation({
      mutationFn: addTeamMember,
      onSuccess: (team) => {
        setTeam(team);
        showSnack('Successfully added new team member', 'success');
      },
      onError: (err) => {
        showSnack(JSON.stringify(err), 'error');
      },
    });
  };

  const useTeamMemberUpdate = () => {
    const { showSnack } = useContext(SnackBarContext);
    const queryteam = useQueryClient();

    const setTeam = useCallback(
      (Team: Team) => {
        queryteam.setQueryData(teamKey, Team);
      },
      [queryteam],
    );

    return useMutation({
      mutationFn: updateTeamMember,
      onSuccess: (team) => {
        setTeam(team);
        showSnack('Successfully updated new team member', 'success');
      },
      onError: (err) => {
        showSnack(JSON.stringify(err), 'error');
      },
    });
  };

  return {
    useTeam,
    useTeamMemberAdd,
    useTeamMemberUpdate,
  };
}

async function getTeam(context: QueryFunctionContext): Promise<Team> {
  const userId = context.queryKey[1];
  return myAxios.get(`/team/${userId}`);
}

async function addTeamMember(payload: TeamMemberInput): Promise<Team> {
  return myAxios.post('/team/member-add', payload);
}

async function updateTeamMember(payload: TeamMemberUpdateInput): Promise<Team> {
  return myAxios.post('/team/member-update', payload);
}

const teamConfig = {
  getTeam: getTeam,
  addTeamMember: addTeamMember,
  updateTeamMember: updateTeamMember,
};

export const { useTeam, useTeamMemberAdd, useTeamMemberUpdate } =
  configureAuth(teamConfig);
