import { Box, Typography } from '@mui/material';

export default function ErrorFallback() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'rgba(26,32,44,1)',
      }}
    >
      <Box>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 24,
            color: '#a0aec0',
          }}
        >
          Unexpected error
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 16,
            color: '#a0aec0',
            marginTop: 4,
          }}
        >
          Please try again later
        </Typography>
      </Box>
    </Box>
  );
}
