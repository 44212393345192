export default function TrailerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="20"
      viewBox="0 0 49 20"
      fill="none"
    >
      <path
        d="M42.6594 10.9311V7.12646L1.20898 7.12646"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="9.30486"
        cy="15.6003"
        r="2.9992"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
      <circle
        cx="16.7853"
        cy="15.6003"
        r="2.9992"
        stroke="#F6AE2D"
        strokeWidth="2"
      />
      <path
        d="M20.0912 15.5621H22.0121L28.8115 7.12402"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.01562"
        y="1.58643"
        width="46"
        height="5.53977"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M3.30675 7.12402V15.5621H5.75106"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
