import { useNotifications } from '@/stores/notifications-store';
import Axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

function authRequestInterceptor(
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  if (config?.headers) {
    config.headers.Accept = 'application/json';
  }
  return config;
}
const apiClient = Axios.create({
  baseURL: '/api',
  withCredentials: true,
});

apiClient.interceptors.request.use(authRequestInterceptor);
apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data as AxiosResponse;
  },
  (error: Error & { response: { data: { errors: { msg: string } } } }) => {
    const errorMessage = error?.response?.data?.errors?.msg;

    if (errorMessage) {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: errorMessage
      });
    }

    return Promise.reject(error);
  },
);

export default apiClient;
