import { createContext, useEffect, useState } from 'react';
import { User } from '@/types/api';
import { useAuthUser } from '@/hooks/useAuth';

export type UserContextType = {
  user: User | undefined;
};

const UserContext = createContext<UserContextType>({
  user: undefined,
});

export default UserContext;

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: authUser } = useAuthUser();
  const [user, setUser] = useState<User | undefined>(authUser);

  useEffect(() => {
    setUser(authUser);
  }, [authUser]);

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
