import '@/styles/app.css';
import AppProvider from '@/AppProvider';
import AppRouter from './routes/AppRouter';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '84e9d80d6bdd962bd20673d7f1249f04Tz04NTIwMyxFPTE3NDA2OTkyMjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

export default function App() {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}
