export default function PermitStatusYellowIcon() {
  return (
    <svg
      width="65"
      height="23"
      viewBox="0 0 65 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.228638"
        y="0.116211"
        width="64"
        height="22"
        rx="11"
        fill="#121212"
      />
      <path
        d="M12.2286 18.1162C16.0946 18.1162 19.2286 14.9822 19.2286 11.1162C19.2286 7.25022 16.0946 4.11621 12.2286 4.11621C8.36264 4.11621 5.22864 7.25022 5.22864 11.1162C5.22864 14.9822 8.36264 18.1162 12.2286 18.1162Z"
        fill="#202D38"
        stroke="#202D38"
        strokeWidth="2"
      />
      <path
        d="M32.2286 18.1162C36.0946 18.1162 39.2286 14.9822 39.2286 11.1162C39.2286 7.25022 36.0946 4.11621 32.2286 4.11621C28.3626 4.11621 25.2286 7.25022 25.2286 11.1162C25.2286 14.9822 28.3626 18.1162 32.2286 18.1162Z"
        fill="#FFA726"
        stroke="#FFA726"
        strokeWidth="2"
      />
      <path
        d="M52.2286 18.1162C56.0946 18.1162 59.2286 14.9822 59.2286 11.1162C59.2286 7.25022 56.0946 4.11621 52.2286 4.11621C48.3626 4.11621 45.2286 7.25022 45.2286 11.1162C45.2286 14.9822 48.3626 18.1162 52.2286 18.1162Z"
        fill="#202D38"
        stroke="#202D38"
        strokeWidth="2"
      />
    </svg>
  );
}
