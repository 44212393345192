import * as Yup from 'yup';

export const createConfigurationSchema = Yup.object().shape({
  vehicle: Yup.string().required(),
  name: Yup.string().required('Name is required'),
  multiUnit: Yup.bool().required(),
  forms: Yup.array().of(
    Yup.object().shape({
      uploads: Yup.array().of(
        Yup.object().shape({
          rca: Yup.string().required('RCA is required'),
          type: Yup.string().required('Type is required'),
          issued: Yup.date().required('Issue date is required'),
          expiry: Yup.date().required('Expiry date is required'),
        }),
      ),
    }),
  ),
});

export const updateConfigurationSchema = Yup.object().shape({
  vehicle: Yup.string().required(),
  name: Yup.string().required('Name is required'),
  multiUnit: Yup.bool().required(),
  forms: Yup.array().of(
    Yup.object().shape({
      permits: Yup.array().of(
        Yup.object().shape({
          rca: Yup.string().required('RCA is required'),
          type: Yup.string().required('Type is required'),
          issueDate: Yup.date().required('Issue date is required'),
          expiryDate: Yup.date().required('Expiry date is required'),
        }),
      ),
    }),
  ),
});
