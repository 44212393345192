import { PropsWithChildren } from 'react';
import SectionTitle from '@/components/SectionTitle';
import { Grid, Box } from '@mui/material';

interface Props {
  title: string;
  description: string;
  renderActions?(): JSX.Element;
  onSubmit(): void;
}

export default function FormSection({
  onSubmit,
  renderActions,
  title,
  description,
  children,
}: PropsWithChildren<Props>) {
  const hasActions = !!renderActions;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={4}>
        <SectionTitle title={title} description={description} />
      </Grid>

      <Grid item xs={12} md={8} px={2}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Box>
            <Grid container spacing={6}>
              <Grid item xs={12} md={12}>
                {children}
              </Grid>
            </Grid>
          </Box>

          {hasActions && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              py={2}
            >
              {renderActions?.()}
            </Box>
          )}
        </form>
      </Grid>
    </Grid>
  );
}
