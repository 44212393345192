const TeamIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2502 27.7082V26.2498C26.2502 23.0282 23.6385 20.4165 20.4168 20.4165H14.5835C11.3618 20.4165 8.75016 23.0282 8.75016 26.2498V27.7082M33.5418 27.7082V26.2498C33.5418 23.0282 30.9302 20.4165 27.7085 20.4165H26.9793M1.4585 27.7082V26.2498C1.4585 23.0282 4.07017 20.4165 7.29183 20.4165H8.021M24.7918 16.0415C27.2081 16.0415 29.1668 14.0828 29.1668 11.6665C29.1668 9.25026 27.2081 7.2915 24.7918 7.2915M10.2085 16.0415C7.79225 16.0415 5.8335 14.0828 5.8335 11.6665C5.8335 9.25026 7.79225 7.2915 10.2085 7.2915M21.8752 11.6665C21.8752 14.0827 19.9164 16.0415 17.5002 16.0415C15.0839 16.0415 13.1252 14.0827 13.1252 11.6665C13.1252 9.25026 15.0839 7.2915 17.5002 7.2915C19.9164 7.2915 21.8752 9.25026 21.8752 11.6665Z"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TeamIcon;
