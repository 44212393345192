import { Box } from '@mui/material';
import PermitTable from '@/features/permits/PermitTable';

export default function Permits() {
  return (
    <Box px={0.5}>
      <PermitTable/>
    </Box>
  );
}
