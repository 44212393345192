import Head from '@/components/Head';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import { useLogin } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { userLoginSchema } from '@/lib/validation/auth';
// import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo';
import { PCheckbox, PTextField } from 'pragmatic-ui';
import { FormikHelpers } from 'formik';
import MyFormik from '@/components/Formik';
import { LoginInput } from '@/types/formInput';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

export default function Login() {
  // TODO: redirectTo
  const redirectTo = '/dashboard';
  const navigate = useNavigate();
  const callback = () =>
    navigate(`${redirectTo ? `${redirectTo}` : '/dashboard'}`, {
      replace: true,
    });
  const login = useLogin({ callback });
  const isCanResetPassword = true;

  const handleSubmit = (
    values: LoginInput,
    { resetForm }: FormikHelpers<LoginInput>,
  ) => {
    login.mutate(values);
    resetForm();
  };

  const formikProps = {
    initialValues: {
      email: '',
      password: '',
      remember: '',
    },
    validationSchema: userLoginSchema,
    onSubmit: handleSubmit,
  };

  return (
    <Grid container>
      <Head title="Login" />
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          background: '#121212',
          height: { xs: '100vh', sm: '100vh', md: 'auto' },
        }}
      >
        <Container
          component="div"
          maxWidth="sm"
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: {
              xs: 'center',
              md: 'flex-end',
            },
          }}
        >
          <Box>
            <Typography variant="h4">Welcome to Permit Pilot</Typography>
          </Box>

          <Box mt={4}>
            <Typography variant="body1">
              See your entire fleet from your desktop. Assign drivers who can
              then manage their fleet routes with our companion mobile app.
            </Typography>
          </Box>

          <Card
            sx={{ width: '100%', borderRadius: '12px 12px 0px 0px', mt: 8 }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingX: '10%',
                mt: 4,
              }}
            >
              <MyFormik<LoginInput> {...formikProps}>
                {({
                  values,
                  handleSubmit,
                  setFieldValue,
                  isValid,
                  isSubmitting,
                }) => (
                  <StyledForm onSubmit={handleSubmit} sx={{ mt: 4 }}>
                    <PTextField
                      required
                      fullWidth
                      id="email"
                      placeholder="Email Address"
                      name="email"
                      autoComplete="email"
                      variant="standard"
                      InputProps={{
                        disableUnderline: false,
                      }}
                    />
                    <PTextField
                      required
                      fullWidth
                      name="password"
                      placeholder="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      variant="standard"
                      InputProps={{
                        disableUnderline: false,
                      }}
                    />
                    <Grid container sx={{ mt: 6 }}>
                      <Grid item xs={6}>
                        <PCheckbox
                          name="remember"
                          label="Stay signed in"
                          checked={values.remember === 'on'}
                          onChange={(e) =>
                            setFieldValue(
                              'remember',
                              e.currentTarget.checked ? 'on' : '',
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          disabled={!isValid || isSubmitting}
                          sx={{ mb: 24 }}
                        >
                          Sign In
                        </Button>
                      </Grid>
                    </Grid>
                  </StyledForm>
                )}
              </MyFormik>

              <Grid
                container
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                {isCanResetPassword && (
                  <Grid item xs={12} sx={{ flexBasis: '30% !important' }}>
                    <Button
                      onClick={() => navigate('/auth/forgot-password')}
                      style={{
                        textDecorationColor: '#757575',
                        color: 'inherit',
                      }}
                    >
                      {/* <Typography color="primary" fontWeight={400}>
                        Forgot password?
                      </Typography> */}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Grid>
      <Grid
        item
        md={6}
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          backgroundColor: '#1F2933',
        }}
      >
        <Container
          component="div"
          maxWidth="xs"
          sx={{
            display: 'flex',
            height: '100vh',
            width: '100vw',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <svg
            width="284"
            height="320"
            viewBox="0 0 284 320"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_8651_5870)">
              <path
                d="M177.668 301.554V318.318H169.028V272.257C175.476 272.257 181.865 271.854 188.031 272.96C195.194 274.249 199.287 281.069 198.523 288.435C197.785 295.45 191.891 301.164 184.896 301.528C182.642 301.645 180.375 301.541 177.681 301.541L177.668 301.554ZM177.836 280.029V293.836C181.774 293.836 186.178 294.656 188.639 290.921C190.323 288.279 190.492 285.311 188.717 282.656C186.45 279.325 181.619 280.029 177.836 280.029Z"
                fill="white"
              />
              <path
                d="M229.586 301.958C229.586 292.431 237.215 284.713 246.49 284.843C256.167 284.973 263.615 292.522 263.563 302.14C263.511 311.472 255.985 319.06 246.736 319.086C237.086 319.112 229.573 311.615 229.586 301.958ZM238.329 301.737C238.187 306.474 241.633 310.235 246.27 310.418C250.92 310.6 254.573 307.125 254.78 302.335C254.988 297.481 251.426 293.563 246.736 293.498C242.112 293.433 238.472 296.999 238.329 301.737Z"
                fill="white"
              />
              <path
                d="M108.897 291.116C111.281 286.248 114.959 283.984 119.934 283.789C127.162 283.515 132.136 287.355 133.677 294.474C133.949 295.723 134.17 296.999 134.182 298.274C134.234 304.561 134.208 310.86 134.208 317.289H129.856C129.856 312.344 129.908 307.437 129.83 302.53C129.791 300.162 129.713 297.754 129.247 295.45C128.302 290.739 125.167 288.188 120.685 288.136C115.983 288.084 112.731 290.53 111.786 295.359C111.242 298.105 111.268 300.981 111.203 303.806C111.112 308.01 111.164 312.227 111.138 316.443C111.138 316.613 111.138 316.757 111.138 317.198H106.76C106.76 314.062 106.812 310.899 106.747 307.723C106.656 303.884 106.747 300.018 106.242 296.231C105.581 291.129 102.33 288.266 97.7699 288.149C92.0833 287.992 88.3915 291.702 88.1713 298.092C87.99 303.493 88.0807 308.895 88.0677 314.296C88.0677 315.272 88.0677 316.261 88.0677 317.341H83.6117C83.6117 317.138 83.6117 317.003 83.6117 316.86C83.6764 309.91 83.3655 302.934 83.9485 296.023C84.7257 286.808 92.7698 281.732 101.488 284.374C104.83 285.389 107.161 287.537 108.884 291.129L108.897 291.116Z"
                fill="white"
              />
              <path
                d="M283.615 318.487C275.26 319.099 269.392 313.632 269.055 305.264C268.913 301.632 269.029 297.858 269.029 294.045H264.288V285.507C265.752 285.507 267.138 285.507 268.887 285.507V272.335C271.529 272.335 275.416 272.335 277.708 272.335C277.708 276.639 277.708 280.994 277.708 285.298C278.058 285.298 278.175 285.285 278.33 285.298C282.074 285.298 283.602 286.405 283.602 291.22C283.602 292.027 283.563 292.782 283.563 293.954H277.89C277.89 298.079 277.721 301.893 277.954 305.693C278.084 307.945 279.47 309.455 281.866 310.053C282.579 310.235 283.447 311.355 283.537 312.123C283.771 314.179 283.615 316.261 283.615 318.487Z"
                fill="white"
              />
              <path
                d="M61.9792 309.181C59.4662 315.727 49.9323 319.593 42.2249 317.302C33.8181 314.803 28.7662 305.979 30.6962 297.22C32.6911 288.2 40.7741 282.604 49.8028 283.984C58.4169 285.298 64.3238 292.886 63.6372 302.192H34.9839C35.4632 307.307 37.743 310.678 41.9659 312.578C46.7069 314.699 51.3831 314.127 55.2304 310.73C57.4325 308.79 59.518 308.947 61.9792 309.181ZM58.7667 298.352C57.9117 291.962 52.5619 287.745 46.1628 288.135C40.6576 288.474 35.5927 293.263 35.4891 298.352H58.7667Z"
                fill="white"
              />
              <path
                d="M4.62065 300.448V317.263H0.333008V271.183C6.29166 271.183 12.2503 270.839 18.0535 271.541C25.5278 272.439 30.3465 279.181 29.8284 286.613C29.2973 294.24 23.3515 300.149 15.7866 300.422C12.1726 300.552 8.55854 300.448 4.6336 300.448H4.62065ZM4.75018 296.478C8.68808 296.478 12.4187 296.726 16.0975 296.413C21.3308 295.984 25.2428 291.35 25.3982 285.949C25.5537 280.665 22.2116 276.045 17.0431 275.394C13.0405 274.899 8.9342 275.29 4.73723 275.29V296.465L4.75018 296.478Z"
                fill="white"
              />
              <path
                d="M225.998 272.166V318.318H217.383C217.383 304.045 217.428 289.771 217.306 275.498C217.293 273.142 218.264 272.166 220.492 272.166C222.487 272.166 223.873 272.179 226.011 272.179L225.998 272.166Z"
                fill="white"
              />
              <path
                d="M203.096 285.48H211.451V318.409H203.096V285.48Z"
                fill="white"
              />
              <path
                d="M150.569 288.461C148.691 288.461 147.395 288.461 145.75 288.461V284.257C147.317 284.257 148.716 284.231 150.439 284.231V274.17H154.908V284.27H163.043V288.461H154.856C154.856 294.565 154.701 300.578 155.245 306.344C155.504 309.155 157.564 311.927 160.388 312.76C163.212 313.593 163.639 314.83 162.901 317.524C158.626 317.159 154.507 315.519 152.538 311.576C151.281 309.038 150.931 306.07 150.711 303.246C150.349 298.535 150.569 293.705 150.569 288.707V288.461Z"
                fill="white"
              />
              <path
                d="M81.7463 284.348V288.604C73.6763 288.604 71.8498 292.209 71.7203 300.63C71.6425 305.927 71.6685 311.927 71.6425 317.224C71.5389 317.224 71.5389 316.847 71.4094 317.224H67.1347C67.1347 316.118 67.1347 312.135 67.1347 312.135C67.1347 312.135 67.044 301.945 67.5881 296.062C68.3264 288.162 74.0778 283.554 81.7463 284.361V284.348Z"
                fill="white"
              />
              <path
                d="M139.351 284.426H143.626V317.198H139.351V284.426Z"
                fill="white"
              />
              <path
                d="M207.176 271.711C210.091 271.685 212.332 273.936 212.344 276.891C212.357 279.832 210.116 282.11 207.202 282.123C204.378 282.123 202.008 279.767 201.956 276.904C201.904 274.066 204.248 271.724 207.163 271.711H207.176Z"
                fill="white"
              />
              <path
                d="M71.7203 300.63C71.6426 305.927 71.6685 311.927 71.6426 317.224C71.5389 317.224 71.5389 317.224 71.4094 317.224"
                fill="white"
              />
              <path
                d="M141.424 279.272C143.062 279.272 144.39 277.938 144.39 276.292C144.39 274.646 143.062 273.312 141.424 273.312C139.785 273.312 138.457 274.646 138.457 276.292C138.457 277.938 139.785 279.272 141.424 279.272Z"
                fill="white"
              />
            </g>
            <path
              d="M224.315 75.6206C215.633 58.0456 198.092 47.2668 179.38 46.9718C175.408 46.9085 147.33 46.9928 142.863 46.9928C131.053 46.9928 127.618 52.9038 127.618 63.4403C127.576 86.8841 127.576 149.45 127.618 175.454C127.597 175.454 127.587 175.454 127.565 175.454V228C139.492 228 149.553 220.055 152.756 209.171C153.051 208.286 153.294 207.264 153.462 206.084C153.694 204.672 153.831 203.218 153.831 201.732C153.831 201.406 153.82 201.09 153.81 200.763C153.81 176.181 153.747 110.907 153.831 86.3784C153.831 77.7174 155.685 73.1023 168.075 73.1023C169.582 73.1023 179.053 72.9759 180.602 73.155C193.93 74.6618 203.833 85.7989 203.317 98.6956C202.78 112.182 191.918 122.087 178.168 123.762C166.358 125.195 163.418 137.755 163.987 148.976C167.348 148.976 175.608 148.871 178.653 148.818C216.487 148.154 240.845 109.095 224.304 75.6311L224.315 75.6206Z"
              fill="url(#paint0_linear_8651_5870)"
            />
            <mask
              id="mask0_8651_5870"
              style={{ maskType: 'luminance' }}
              maskUnits="userSpaceOnUse"
              x="127"
              y="46"
              width="103"
              height="176"
            >
              <path
                d="M172.542 148.965C175.903 148.965 175.619 148.86 178.664 148.807C216.497 148.144 240.856 109.085 224.315 75.6206C215.633 58.0456 198.091 47.2668 179.38 46.9718C175.408 46.9085 147.33 46.9928 142.863 46.9928C131.053 46.9928 127.618 52.9038 127.618 63.4403C127.576 87.9905 127.586 148.07 127.618 172.62C127.639 184.653 131.379 227.463 136.405 220.793C144.528 212.627 153.81 196.064 153.81 177.814C153.81 153.264 153.747 110.918 153.831 86.3678C153.831 77.7068 155.685 73.0918 168.075 73.0918C169.582 73.0918 179.053 72.9654 180.602 73.1445C193.93 74.6512 203.833 85.7883 203.317 98.6851C202.78 112.172 191.981 122.445 178.126 122.614C175.229 122.645 175.661 122.761 172.521 122.761V148.955L172.542 148.965Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_8651_5870)">
              <g style={{ mixBlendMode: 'multiply' }} opacity="0.8">
                <rect
                  x="116.471"
                  y="0.90625"
                  width="131.696"
                  height="131.707"
                  fill="url(#pattern0_8651_5870)"
                />
              </g>
            </g>
            <path
              d="M201.768 49.5321C194.762 18.3335 166.021 0.326531 134.856 0.326531C121.107 0.326531 105.725 0.294922 94.8204 0.294922C80.692 0.305458 78.311 7.67049 78.311 17.3114C78.311 47.0877 78.2583 147.343 78.332 177.119C78.332 177.224 78.3426 177.319 78.3426 177.425V227.779C95.2629 227.779 109.086 214.482 109.918 197.771C110.002 196.822 110.055 195.832 110.055 194.789C110.129 164.159 110.002 83.386 110.097 52.7563C110.15 34.8337 112.015 32.1258 128.187 32.1258C130.41 32.1258 142.695 32.0836 145.002 32.484C160.848 35.2024 172.205 49.0264 171.173 64.3149C170.709 71.2585 167.991 77.3591 163.924 82.2586C161.764 84.8574 157.37 89.1689 153.815 89.8238C153.825 96.7569 153.762 108.853 153.773 123.214C154.552 123.172 155.19 123.161 156.001 122.961C187.735 114.532 209.217 82.7117 201.779 49.5637L201.768 49.5321Z"
              fill="url(#paint1_linear_8651_5870)"
            />
            <mask
              id="mask1_8651_5870"
              style={{ maskType: 'luminance' }}
              maskUnits="userSpaceOnUse"
              x="79"
              y="0"
              width="125"
              height="124"
            >
              <path
                d="M201.768 49.5327C194.762 18.3341 166.021 0.327148 134.856 0.327148C77.2971 0.327148 48.4188 32.1264 128.187 32.1264C130.41 32.1264 142.695 32.0843 145.002 32.4846C160.848 35.2031 172.205 49.027 171.173 64.3155C170.709 71.2591 167.991 77.3597 163.924 82.2592C161.763 84.858 157.382 89.1695 153.827 89.8244C153.837 96.7575 153.774 108.853 153.784 123.215C154.564 123.173 155.19 123.162 156.001 122.962C187.735 114.533 209.217 82.7123 201.779 49.5644L201.768 49.5327Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_8651_5870)">
              <g style={{ mixBlendMode: 'multiply' }} opacity="0.8">
                <rect
                  x="71.1677"
                  y="39.8916"
                  width="131.696"
                  height="130.653"
                  fill="url(#pattern1_8651_5870)"
                />
              </g>
            </g>
            <defs>
              <pattern
                id="pattern0_8651_5870"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use transform="scale(0.008)" />
              </pattern>
              <pattern
                id="pattern1_8651_5870"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use transform="scale(0.008 0.00806452)" />
              </pattern>
              <linearGradient
                id="paint0_linear_8651_5870"
                x1="186.06"
                y1="70.5841"
                x2="127.19"
                y2="174.734"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F26419" />
                <stop offset="0.68" stopColor="#CE4721" />
                <stop offset="1" stopColor="#BC3926" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_8651_5870"
                x1="146.667"
                y1="35.4553"
                x2="75.2582"
                y2="149.961"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F26419" />
                <stop offset="0.68" stopColor="#CE4721" />
                <stop offset="1" stopColor="#BC3926" />
              </linearGradient>
              <clipPath id="clip0_8651_5870">
                <rect
                  width="283.334"
                  height="47.8956"
                  fill="white"
                  transform="translate(0.333008 271.19)"
                />
              </clipPath>
              <image id="image0_8651_5870" width="125" height="125" />
              <image id="image1_8651_5870" width="125" height="124" />
            </defs>
          </svg>
        </Container>
      </Grid>
    </Grid>
  );
}
