export default function SaveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M13.0693 6.86686H7.736C7.26929 6.86686 7.03594 6.86686 6.85768 6.77603C6.70088 6.69614 6.57339 6.56866 6.4935 6.41185C6.40267 6.23359 6.40267 6.00024 6.40267 5.53353V2.7002M14.736 17.7002V12.3669C14.736 11.9002 14.736 11.6668 14.6452 11.4885C14.5653 11.3317 14.4378 11.2043 14.281 11.1244C14.1027 11.0335 13.8694 11.0335 13.4027 11.0335H7.736C7.26929 11.0335 7.03594 11.0335 6.85768 11.1244C6.70088 11.2043 6.57339 11.3317 6.4935 11.4885C6.40267 11.6668 6.40267 11.9002 6.40267 12.3669V17.7002M18.0693 7.97143V13.7002C18.0693 15.1003 18.0693 15.8004 17.7969 16.3352C17.5572 16.8056 17.1747 17.188 16.7043 17.4277C16.1695 17.7002 15.4695 17.7002 14.0693 17.7002H7.06934C5.6692 17.7002 4.96914 17.7002 4.43436 17.4277C3.96395 17.188 3.5815 16.8056 3.34182 16.3352C3.06934 15.8004 3.06934 15.1003 3.06934 13.7002V6.70019C3.06934 5.30006 3.06934 4.6 3.34182 4.06522C3.5815 3.59481 3.96395 3.21236 4.43436 2.97268C4.96914 2.7002 5.6692 2.7002 7.06934 2.7002H12.7981C13.2058 2.7002 13.4096 2.7002 13.6014 2.74625C13.7714 2.78707 13.934 2.85441 14.0831 2.9458C14.2513 3.04886 14.3955 3.19299 14.6837 3.48124L17.2883 6.08581C17.5765 6.37407 17.7207 6.51819 17.8237 6.68639C17.9151 6.83551 17.9825 6.99808 18.0233 7.16814C18.0693 7.35995 18.0693 7.56378 18.0693 7.97143Z"
        stroke="#202D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
