import { Box, Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom';

type ApiError = {
  statusText: string;
  message: string;
  status: string;
  error: {
    message: string;
    stack: string;
  };
};

export default function ErrorPage() {
  const error = useRouteError();

  if (!error || typeof error !== 'object') {
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'rgba(26,32,44,1)',
      }}
    >
      <Box>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 24,
            color: '#a0aec0',
          }}
        >
          Unexpected server error
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 16,
            color: '#a0aec0',
            marginTop: 4,
          }}
        >
          Please try again later
        </Typography>
      </Box>
    </Box>;
  }

  console.log('the error', error);

  const apiError = error as ApiError;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'rgba(26,32,44,1)',
      }}
    >
      <Box>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 24,
            color: '#a0aec0',
          }}
        >
          {apiError.status} | {apiError.statusText || apiError.message}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 16,
            color: '#a0aec0',
            marginTop: 4,
          }}
        >
          {apiError.error.message}
        </Typography>
      </Box>
    </Box>
  );
}
