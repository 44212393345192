/* eslint-disable @typescript-eslint/no-explicit-any */
import myAxios from '@/lib/api-client';
import { useCallback, useState, useMemo } from 'react';
import ActionSection from '@/features/profile/ActionSection';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { deleteUserSchema } from '@/lib/validation/userProfile';
import { PTextField } from 'pragmatic-ui';
import Formik from '@/components/Formik';
import { user } from '@/mockData';

type ValuesProps = {
  password: string;
};

export default function DeleteUserForm() {
  const [confirmingUserDeletion, setConfirmingUserDeletion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payload] = useState({
    password: '',
  });

  // TODO ON HOLD: set up correctly
  const handleSubmit = useCallback((values: ValuesProps) => {
    myAxios
      .delete(`/user/current-user/destroy/${user._id}/${values.password}`)
      .then(({ data }) => {
        console.log(data);
        closeModal();
      })
      .catch((err: Error) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  function confirmUserDeletion() {
    setConfirmingUserDeletion(true);
  }

  function closeModal() {
    setConfirmingUserDeletion(false);
  }

  const formikProps = useMemo(() => {
    return {
      initialValues: payload,
      validationSchema: deleteUserSchema,
      onSubmit: handleSubmit,
    };
  }, [payload, handleSubmit]);

  return (
    <ActionSection
      title={'Delete Account'}
      description={'Permanently delete your account.'}
    >
      <Typography variant="body1">
        Once your account is deleted, all of its resources and data will be
        permanently deleted. Before deleting your account, please download any
        data or information that you wish to retain.
      </Typography>

      <Box mt={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={confirmUserDeletion}
        >
          Delete Account
        </Button>
      </Box>

      {/* <!-- Delete Account Confirmation Modal --> */}
      <Dialog open={confirmingUserDeletion} onClose={closeModal} maxWidth="xs">
        <DialogTitle>
          <Typography variant="h6">Delete Account</Typography>
        </DialogTitle>
        <Formik {...formikProps}>
          {({ handleSubmit }: any) => (
            <>
              <form onSubmit={handleSubmit}>
                <DialogContent title={'Delete Account'}>
                  <Typography variant="body1">
                    Are you sure you want to delete your account? Once your
                    account is deleted, all of its resources and data will be
                    permanently deleted.
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    Please enter your password to confirm you would like to
                    permanently delete your account.
                  </Typography>
                  <Box mt={2}>
                    <PTextField
                      type="password"
                      name="password"
                      placeholder="Password"
                      label="Password"
                      fullWidth
                      autoComplete="current-password"
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeModal}>Cancel</Button>

                  <Button
                    type="submit"
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                  >
                    Delete Account
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        </Formik>
      </Dialog>
    </ActionSection>
  );
}
