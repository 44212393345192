import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Chip, darken, Fab, Typography } from '@mui/material';
import CustomDataGrid from '@/components/CustomDataGrid';
import PencilOutlinedIcon from '@/components/icons/PencilOutlinedIcon';
import MobileCraneIcon from '@/components/icons/MobileCraneIcon';
import TrailerIcon from '@/components/icons/TrailerIcon';
import TractorIcon from '@/components/icons/TractorIcon';
import { useDeleteVehicle, useVehicles } from '@/hooks/useVehicles';
import { Company } from '@/types/api';
import TableDeleteActionWithConfirmation from '@/components/TableDeleteActionWithConfirmation';

export default function VehiclesTable() {
  const { data: vehicles = [] } = useVehicles();
  const deleteVehicle = useDeleteVehicle();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId');

  const initialState = useMemo(() => {
    const state: any = {
      pagination: { paginationModel: { pageSize: 10 } },
    };

    if (companyId) {
      state.filter = {
        filterModel: {
          items: [
            {
              field: 'company',
              operatorValue: 'equals',
              value: vehicles.find(
                (vehicle) => vehicle.company._id === companyId,
              )?.company.name,
            },
          ],
        },
      };
    }
    return state;
  }, [companyId]);

  const handleCellClick = (params: any) => {
    navigate(`/vehicles/${params.row._id}`);
  };

  const columns = [
    {
      field: 'vehicleType',
      headerName: '',
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => {
        if (params.value === 'Trailer') {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <TrailerIcon />
            </Box>
          );
        }

        if (params.value === 'Tractor') {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <TractorIcon />
            </Box>
          );
        }

        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 3 }}
          >
            <MobileCraneIcon />
          </Box>
        );
      },
    },
    {
      field: 'rego',
      headerName: 'Rego No.',
      minWidth: 75,
      flex: 1,
      renderCell: (params: any) => (
        <>
          <Chip
            sx={{ background: '#fff', color: '#202D38', borderRadius: '4px' }}
            label={params.value}
          />
        </>
      ),
    },
    {
      field: 'fleetNumber',
      headerName: 'Fleet No.',
      minWidth: 175,
      flex: 1,
      valueGetter: (fleetNumber: string) => fleetNumber ?? '',
    },
    {
      field: 'make',
      headerName: 'Make',
      minWidth: 150,
      flex: 1,
      valueGetter: (make: string) => make ?? '',
    },
    {
      field: 'model',
      headerName: 'Model',
      minWidth: 150,
      flex: 1,
      valueGetter: (model: string) => model ?? '',
    },
    {
      field: 'company',
      headerName: 'Client',
      minWidth: 300,
      flex: 1,
      valueGetter: (company: Company) => company.name,
    },
    {
      field: 'branch',
      headerName: 'Branch',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'edit',
      headerName: 'Actions',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => (
        <>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            sx={{
              mr: 2,
              '&:hover': {
                backgroundColor: darken('#F6AE2D', 0.2),
              },
            }}
            onClick={() => handleCellClick(params)}
          >
            <PencilOutlinedIcon />
          </Fab>
          <TableDeleteActionWithConfirmation
            title={'Delete Vehicle'}
            message={
              <>
                <Typography>
                  This vehicle will no longer be visible by all associated
                  members.
                </Typography>
                <Typography>
                  Do you still wish to permanently delete this vehicle?
                </Typography>
              </>
            }
            handleDeleteClick={() => deleteVehicle.mutate(params.row._id)}
          />
        </>
      ),
    },
  ];

  return (
    <CustomDataGrid
      rows={vehicles}
      columns={columns}
      getRowId={(row) => row._id}
      hideFooter={true}
      disableRowSelectionOnClick={true}
      initialState={initialState}
    />
  );
}
