import { CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';
import ErrorPage from '@/pages/ErrorPage';
import AppLayout from '@/layouts/AppLayout';

export default function AppRoot() {
  const { pathname } = useLocation();

  return (
    <AppLayout>
      <Suspense fallback={<CircularProgress />}>
        <ErrorBoundary key={pathname} fallback={<ErrorPage />}>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </AppLayout>
  );
}
