import { Box } from '@mui/material';
import TeamCreate from '@/features/teams/TeamCreate';

export default function Teams() {
  return (
    <Box px={0.5}>
      <TeamCreate />
    </Box>
  );
}
