import { useCallback, useContext } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  QueryFunction,
  MutationFunction,
} from '@tanstack/react-query';
import myAxios from '@/lib/api-client';
import type { Team, CustomQueryOptions } from '@/types/api';
import { TeamInput } from '@/types/formInput';
import { rqKeys } from '@/lib/react-query';
import { SnackBarContext } from 'pragmatic-ui';

interface Config<Teams, TeamsCredentials> {
  getTeams: QueryFunction<Teams, QueryKey>;
  createTeam: MutationFunction<Team, TeamsCredentials>;
  teamsKey?: QueryKey;
}

export function configureAuth<Teams, Error, TeamsCredentials>(
  config: Config<Teams, TeamsCredentials>,
) {
  const { getTeams, createTeam, teamsKey = [rqKeys.teams] } = config;

  const useTeams = (options?: CustomQueryOptions<Teams, Error>) =>
    useQuery({
      queryKey: teamsKey,
      queryFn: getTeams,
      ...options,
    });

  const useTeamCreate = () => {
    const { showSnack } = useContext(SnackBarContext);
    const queryteams = useQueryClient();

    const setteams = useCallback(
      (Team: Team) => {
        queryteams.setQueryData(teamsKey, (current: Team[] | undefined) =>
          current ? [...current, Team] : [Team],
        );
      },
      [queryteams],
    );

    return useMutation({
      mutationFn: createTeam,
      onSuccess: (teams) => {
        setteams(teams);
        showSnack('Successfully createed new team', 'success');
      },
      onError: (err) => {
        showSnack(JSON.stringify(err), 'error');
      },
    });
  };

  return {
    useTeams,
    useTeamCreate,
  };
}

const getTeams = (): Promise<Team[]> => {
  return myAxios.get('/teams/');
};

async function createTeam(payload: TeamInput): Promise<Team> {
  return myAxios.post('/teams/create', payload);
}

const teamsConfig = {
  getTeams: getTeams,
  createTeam: createTeam,
};

export const { useTeams, useTeamCreate } = configureAuth(teamsConfig);
