import Head from '@/components/Head';
import CreateConfigurationForm from '@/features/configurations/CreateConfigurationForm';
import { useCompanies } from '@/hooks/useCompanies';
import { useVehicles } from '@/hooks/useVehicles';
import { Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function CreateConfiguration() {
  const { vehicleId } = useParams();
  const { data: vehicles, isLoading } = useVehicles();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const company = vehicles?.find(
    (vehicle) => vehicle._id === vehicleId,
  )?.company;

  return (
    <>
      <Head title="Create configuration" />
      <Box>
        <CreateConfigurationForm
          vehicleId={vehicleId || ''}
        />
      </Box>
    </>
  );
}
