/* eslint-disable */

import { ReactNode } from 'react';
import {
  Formik as BaseFormik,
  FormikProps,
  FormikHelpers,
  FormikConfig,
  FormikValues,
} from 'formik';

export interface MyFormikProps<T extends FormikValues>
  extends Omit<FormikConfig<T>, 'children'> {
  initialValues: T;
  validationSchema?: unknown;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
  children:
    | ReactNode
    | ((
        formikProps: FormikProps<T> & { submitDisabled: boolean },
      ) => ReactNode);
}

function MyFormik<T extends FormikValues>(props: MyFormikProps<T>) {
  const { children, ...restProps } = props;
  return (
    <BaseFormik<T> {...restProps}>
      {(formikProps: FormikProps<T>) => {
        if (typeof children === 'function') {
          return children({
            ...formikProps,
            submitDisabled: false,
          });
        }
        return children;
      }}
    </BaseFormik>
  );
}

export default MyFormik;
