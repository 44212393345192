export default function VehicleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M23.8358 27.122H12.6632"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5894 19.2147H27.4831C27.0802 19.2147 26.7536 18.8881 26.7536 18.4852V13.3789H31.8597"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5657 25.6908C28.1916 26.3166 28.3789 27.2579 28.0402 28.0756C27.7015 28.8934 26.9036 29.4267 26.0184 29.4267C25.1333 29.4267 24.3353 28.8936 23.9966 28.0758C23.6578 27.2581 23.845 26.3168 24.4708 25.6909L24.471 25.6907C25.3256 24.8362 26.7111 24.8362 27.5657 25.6907V25.6907"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.41058 6.08396H15.0821"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0821 6.08398C15.8879 6.08398 16.5411 6.73717 16.5411 7.54293"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5411 7.54297V16.2966"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.14637 25.6909C7.77228 26.3168 7.95955 27.258 7.62087 28.0758C7.28218 28.8936 6.48423 29.4268 5.5991 29.4268C4.71397 29.4268 3.91597 28.8937 3.57721 28.076C3.23845 27.2582 3.42563 26.317 4.05148 25.691L4.05192 25.6906C4.46228 25.2803 5.01886 25.0498 5.59919 25.0498C6.17952 25.0499 6.73605 25.2805 7.14634 25.6909V25.6909"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.777 25.6909C12.4029 26.3168 12.5902 27.258 12.2515 28.0758C11.9128 28.8936 11.1149 29.4268 10.2298 29.4268C9.34463 29.4268 8.54663 28.8937 8.20787 28.076C7.86911 27.2582 8.05629 26.317 8.68215 25.691L8.68258 25.6906C9.09294 25.2803 9.64952 25.0498 10.2298 25.0498C10.8102 25.0499 11.3667 25.2805 11.777 25.6909V25.6909"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2054 27.2387H31.1305C31.9362 27.2387 32.5894 26.5855 32.5894 25.7798V15.5267C32.5894 15.0736 32.4839 14.6267 32.2812 14.2215L30.4771 10.6146C29.9828 9.62623 28.9725 9.00195 27.8675 9.00195H20.9179"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.41058 20.6734H20.9179V6.08398"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
